import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// IMAGES
import DefaultClient from "../../Global/icons/defaultClient.svg";

const StyledClientCard = styled(NanoFlex)`
  height: auto;
  margin: 8px;

  .clientCardWrapper {
    background-color: ${(props) => props.theme.color.greyPalette.white};
    border-radius: 10px;
    overflow: hidden;
    cursor: ${(props) => (props.selectedCard ? "inherit" : "pointer")};
    pointer-events: ${(props) => (props.selectedCard ? "none" : "unset")};
    position: relative;
    border: 1px solid;
    border-color: ${(props) => (props.selectedCard ? props.theme.color.greyPalette.grey : props.theme.color.greyPalette.white)};
    box-shadow: ${(props) => (props.selectedCard ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none")};
    .statusClientWrapper {
      width: auto;
      height: auto;
      position: absolute;
      top: 8px;
      right: 8px;
      .statusClient {
        width: 16px;
        height: 16px;
        border-radius: 50px;
        background-color: ${(props) => (props.inactiveStatus ?  props.theme.color.main.red : props.theme.color.main.green)};
        border: 2px solid ${(props) => props.theme.color.greyPalette.white};
      }
    }

    .logoContainer {
      background-color: ${(props) => props.theme.color.greyPalette.white};
      width: 180px;
      padding: 10px;
      img {
        max-width: 80%;
        max-height: 120px;
        width: 100%;
        height: 100%;
      }
    }
    .infoContainer {
      background-color: ${(props) => props.theme.color.greyPalette.secLighterGrey}; 
      padding: 24px;

      

      .clientName {
        margin-bottom: 16px;
      }
      .infoWrapper {
        margin-bottom: 8px;
        h6 {
          margin-bottom: 4px;
          b {
            text-transform: uppercase;
            font-size: 12px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &:hover {
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.35);
      /* transform: scale(1.05); */
    }
  }
`;

class ClientCard extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      item: this.props.item
    }
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledClientCard flexDirection="column" selectedCard={this.props.selectedCard} inactiveStatus={this.props.inactiveStatus}>
        {this.state.item && (
          <NanoFlex className="clientCardWrapper">
            <NanoFlex className="statusClientWrapper">
              <NanoFlex className="statusClient" title="Ativo"></NanoFlex>
            </NanoFlex>
            <NanoFlex className="logoContainer">
              <img src={this.state.item.clientProfileImageFilePath ?? DefaultClient} />
            </NanoFlex>
            <NanoFlex className="infoContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="clientName" justifyContent="flex-start" alignItems="flex-start">
                <Type.h4>
                  <b>{this.state.item.name}</b>
                </Type.h4>
              </NanoFlex>

              <NanoFlex className="infoWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>Versão</b>
                </Type.h6>
                <Type.p>{this.state.item.version?.name}</Type.p>
              </NanoFlex>

              <NanoFlex className="infoWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>Licença</b>
                </Type.h6>
                {this.state.item.licenseStartDate && this.state.item.licenseEndDate && (
                  <Type.p>{this.formatDate(this.state.item.licenseStartDate)} a {this.formatDate(this.state.item.licenseEndDate)}</Type.p>
                )}
                {this.state.item.licenseStartDate && !this.state.item.licenseEndDate && (
                  <Type.p>Iníciou a {this.formatDate(this.state.item.licenseStartDate)}</Type.p>
                )}
              </NanoFlex>

              <NanoFlex className="infoWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>Acesso Completo</b>
                </Type.h6>
                <Type.p>{this.state.item.fullLicensesAmount} utilizadores</Type.p>
              </NanoFlex>

              <NanoFlex className="infoWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Type.h6>
                  <b>Acesso Colaboradores</b>
                </Type.h6>
                <Type.p>{this.state.item.consultantLicensesAmount} utilizadores</Type.p>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledClientCard>
    );
  }
}

export default ClientCard;

// --- // Documentation // --- //
/*
    import ClientCard from './Cards/ClientCard.js';
    
    <ClientCard />
    */