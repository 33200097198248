import React from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";

const ButtonIconStyled = styled(NanoFlex)`
  width: auto;
  height: auto;
  user-select:none;
  .iconWrapper {
    width: ${(props) => (props.small ? "42px" : "46px")};
    height: ${(props) => (props.small ? "42px" : "46px")};
    /* padding: 12px; */
    user-select: none;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 50px;
    background-color: ${(props) => (props.blue ? props.theme.color.main.blue : props.darkBlue ? props.theme.color.main.darkBlue : props.orange ? props.theme.color.main.orange : props.lightGrey ? props.theme.color.greyPalette.lightGrey : props.theme.color.greyPalette.white)};
    transition: ${(props) => props.theme.transition};
    img {
      width: 22px;
      max-height: 24px;
    }
  }
`;

const ButtonIcon = (props) => {
  return (
    <ButtonIconStyled className="buttonIcon" blue={props.blue} darkBlue={props.darkBlue} orange={props.orange} lightGrey={props.lightGrey} small={props.small}>
      <NanoFlex className="iconWrapper" onClick={() => props.clickAction(props.id)}>
        <img src={props.icon} alt="icon" />
      </NanoFlex>
    </ButtonIconStyled>
  );
};

// Set default props
ButtonIcon.defaultProps = {
  icon: "", // Image Src
  clickAction: () => null,
};

export default ButtonIcon;

/*
  Doc
  import ButtonIcon from './Buttons/CButtonIcon.js';
  
  <ButtonIcon label="Contacte-nos"/>
  <ButtonIcon pink label="Contacte-nos"/>
  <ButtonIcon blue label="Contacte-nos"/>
  */
