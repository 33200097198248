import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import { NavLink } from "react-router-dom";
// import Type from "../../Global/Typography.js";

// SERVICES
import clientService from "../../../services/ClientService";
import accountService from "../../../services/AccountService";

// COMPONENTS
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import SearchBar from "../../Elements/Forms/SearchBar.js";
import ButtonIcon from "../../Elements/Buttons/ButtonIcon.js";
import ClientCard from "../../Elements/Cards/ClientCard.js";
import ClientProfile from "../../Elements/Cards/ClientProfile.js";
import Loading from "../../Elements/Loadings/Loading.js";

// import Input from "../../Elements/Forms/Input.js";

// IMAGES
import Background from "../../Global/images/bckHome.svg";
// import { ReactComponent as MlogMedialog } from "../../Global/images/mlogMedialogLogo.svg";
import { ReactComponent as LicenseManagementLogo } from "../../Global/images/licenseManagementLogo.svg";
import Logout from "../../Global/icons/logout.svg";
import Type from "../../Global/Typography.js";
// import Type from "../../Global/Typography.js";

const StyledHome = styled(NanoFlex)`
  height: 100vh;
  width: 100vw;
  background-image: url(${Background});
  background-repeat: no-repeat, repeat;
  background-size: cover;
  .homeWrapper {
    .headerWrapper {
      padding: 16px 30px;
      height: auto;
      .logoContainer {
        width: auto;
        svg {
          height: 40px;
          width: auto;
        }
      }
      .rightAside {
        width: auto;
        .elementWrapper {
          width: auto;
          margin-left: 16px;
        }
      }
    }
    .mainListWrapper {
      background: rgba(71, 71, 71, 0.1);
      padding: 20px 0;
      overflow: hidden;
      .gridWrapper {
        width: 100%;
        height: 100%;
        padding: 0px 30px 10px 30px;
        overflow: auto;
        display: grid;
        gap: 8px;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-template-rows: auto;
        grid-auto-flow: dense;
        position: relative;
      }
      .clientProfileWrapper {
        margin: 0 30px;
        width: 60vw;
      }
    }
    .noResults {
      overflow: hidden;
      h1 {
        text-transform: uppercase;
        color: ${(props) => props.theme.color.greyPalette.grey};
      }
      p {
        color: ${(props) => props.theme.color.greyPalette.grey};
      }
    }
    .loadingContainer {
      background: rgba(71, 71, 71, 0.1);
      padding: 20px 0;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: 800px) {
    .homeWrapper {
      .headerWrapper {
        padding: 24px;
        flex-direction: column;
        .rightAside {
          margin-top: 24px;
          .elementWrapper {
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
`;

export class Home extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      versionId: this.props.versionId,
      freeText: '',
      clients: [],
      selectedClientId: -1,
      lastSelectedClientId: -1,
      isLoading: true
    }
  }
  
  componentDidMount() {
    super.componentDidMount();
    
    this.getClients();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.freeText ?? '') !== (this.state.freeText ?? '') && (this.state.freeText?.length > 2 || prevState.freeText?.length > 0)) {
      this.getClients();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  handleFreeTextChange = (freeText) => {
    if ((this.state.freeText ?? '') !== (freeText ?? '')){
      this.setState({
        ...this.state,
        freeText: freeText
      });
    }
  }

  setSelectedClientId = (id) => {
    this.setState({ selectedClientId: id });
  }

  getClients = () => {
    this.setState({
      ...this.state,
      isLoading: true
    });

    clientService
      .getClients(this.state.versionId, this.state.freeText)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401)
            window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        let dataSelectedClientId = this.state.selectedClientId;
        
        if (data?.length === 0 && dataSelectedClientId !== 0)
          dataSelectedClientId = -1;
        
        if (data?.length > 0 && dataSelectedClientId === -1)
          dataSelectedClientId = data[0].id ?? -1;

        if (data?.length > 0 && dataSelectedClientId > 0)
          dataSelectedClientId = data.find(client => client.id === dataSelectedClientId)?.id ?? -1;

        this.setState({ 
          ...this.state,
          selectedClientId: dataSelectedClientId,
          clients: data, 
          isLoading: false
        });
      });
  }

  logout = () => {
    this.setState({ isLoading: true });

    accountService
      .logout()
      .then()
      .then(() => {
        accountService.invalidateToken();

        window.dispatchEvent(new Event("sendToLogin"));
      });
  }

  render() {
    return (
      <StyledHome>
        <NanoFlex className="homeWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="headerWrapper" justifyContent="space-between">
            <NavLink to="/">
              <NanoFlex className="logoContainer" justifyContent="flex-start">
                <LicenseManagementLogo />
              </NanoFlex>
            </NavLink>
            <NanoFlex className="rightAside" justifyContent="flex-end">
              <NanoFlex className="elementWrapper" onClick={() => { this.setState({ ...this.state, selectedClientId: 0, lastSelectedClientId: this.state.selectedClientId }) }}>
                <ButtonCTA darkBlue smallCta label="Novo Cliente" />
              </NanoFlex>
              <NanoFlex className="elementWrapper">
                <SearchBar placeholder="Pesquisar..." handleFreeTextChange={this.handleFreeTextChange} freeText={this.state.freeText} />
              </NanoFlex>
              <NanoFlex className="elementWrapper" onClick={() => { this.logout(); }}>
                <ButtonIcon small icon={Logout} />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {this.state.isLoading && (
            <NanoFlex className="loadingContainer">
              <Loading />
            </NanoFlex>
          )}
     
          {!this.state.isLoading &&(
            <NanoFlex className="mainListWrapper" justifyContent="flex-start" alignItems="flex-start">
              {this.state.clients?.length > 0 && (
                <div className="gridWrapper">
                  {this.state.clients?.map((item) => {
                    return (
                      <NanoFlex
                        className="clientCardContainer"
                        key={`${item.id}`}
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            selectedClientId: item.id
                          });
                        }}>
                        <ClientCard inactiveStatus={!item.isActive} selectedCard={this.state.selectedClientId === item.id} item={item} />
                      </NanoFlex>
                    );
                  })}
                </div>
              )}

              {this.state.clients?.length === 0 && (
                <NanoFlex className="noResults" flexDirection="column">
                  <Type.h1><b>Sem Resultados</b></Type.h1>
                  {(this.state.selectedClientId === -1 && this.state.freeText?.length === 0) && (
                    <Type.p>Crie um Novo Cliente</Type.p>
                  )}
                </NanoFlex>
              )}

              {(this.state.selectedClientId > -1) && (
                <NanoFlex className="clientProfileWrapper" key={`profile_${this.state.selectedClientId}`} >
                  <ClientProfile
                    versionId={this.state.versionId}
                    clientId={this.state.selectedClientId}
                    lastSelectedClientId={this.state.lastSelectedClientId}
                    setSelectedClientId={this.setSelectedClientId}
                    getClients={this.getClients}
                  />
                </NanoFlex>
              )}
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledHome>
    );
  }
}
