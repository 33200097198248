import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import ButtonIcon from "../../Elements/Buttons/ButtonIcon.js";
import Input from "../../Elements/Forms/Input.js";
import Textarea from "../../Elements/Forms/Textarea.js";
import SearchBar from "../../Elements/Forms/SearchBar.js";
import ClientCard from "../../Elements/Cards/ClientCard.js";
import CalendarRange from "../../Elements/Forms/CalendarRange.js";

// IMAGES
import { ReactComponent as MlogLogo } from "../../Global/images/mlogLogoSignature.svg";
import Logout from "../../Global/icons/logout.svg";

const StyledGuidelines = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.main.grey};
  padding-top: 30px;
  .guidelineBlock {
    height: auto;
    padding: 62px;
  }
`;

export class Guidelines extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledGuidelines flexDirection={"column"} justifyContent={"flex-start"}>
        {/* MLOG GUIDELINES */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"}>
          <MlogLogo />
          <Type.h1>
            <b>GUIDELINES</b>
          </Type.h1>
        </NanoFlex>

        {/* TYPOGRAPHY */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Type.h1>
            <b>H1</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h1>
          <Type.h2>
            <b>H2</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h2>
          <Type.h3>
            <b>H3</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h3>
          <Type.h4>
            <b>H4</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h4>
          <Type.h5>
            <b>H5</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h5>
          <Type.h6>
            <b>H6</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.h6>
          <Type.p>
            <b>P</b> Lorem ipsum <b>dolor sit amet</b>
          </Type.p>
        </NanoFlex>

        {/* BUTTONS CTA */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <ButtonCTA label="Tentar Novamente" />
          <ButtonCTA darkBlue label="Iniciar Sessão" />

          <ButtonCTA smallCta label="Enviar" />
          <ButtonCTA orange smallCta label="Filtrar" />
        </NanoFlex>

        {/* BUTTONS ICONS */}
        <NanoFlex className="guidelineBlock" alignItems={"flex-start"}>
          <ButtonIcon lightGrey small icon={Logout} />
        </NanoFlex>

        {/* INPUT */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Input id={0} title="Username" placeholder="Username" error={false} onChangeAction={(currentValue, id, title, type) => console.log("onChangeAction", currentValue, id, title, type)} />
          <Input
            id={1}
            type={this.state.showPassword ? "text" : "password"}
            title="Password"
            placeholder=""
            eye={true}
            showPassword={this.state.showPassword}
            error={false}
            onClickEyeToggle={() => this.setState({ showPassword: !this.state.showPassword })}
            onChangeAction={(currentValue, id, title, type) => console.log("onChangeAction", currentValue, id, title, type)}
          />
          <Input id={2} title="Error" placeholder="Error" error={true} onChangeAction={(currentValue, id, title, type) => console.log("onChangeAction", currentValue, id, title, type)} />
        </NanoFlex>

        {/* TEXTAREA */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <Textarea title="Mensagem" placeholder="Insira uma mensagem..." />
        </NanoFlex>

        {/* SEARCHBAR */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <SearchBar placeholder="Pesquisar..." />
        </NanoFlex>

        {/* SEARCHBAR */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"}>
          <CalendarRange onChangeSelection={(obj) => console.log(obj)}/>
        </NanoFlex>

        {/* CLIENT CARDS */}
        <NanoFlex className="guidelineBlock" flexDirection={"column"} alignItems={"flex-start"} width="700px">
          <ClientCard />
        </NanoFlex>
      </StyledGuidelines>
    );
  }
}
