import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import { NavLink } from "react-router-dom";

// IMAGES
import Background from "../../Global/images/bckLogin.svg";
import { ReactComponent as IconMlog } from "../../Global/images/iconMlog.svg";
import { ReactComponent as LicenseManagementLogo } from "../../Global/images/licenseManagementLogo.svg";

const StyledMenu = styled(NanoFlex)`
  height: 100vh;
  width: 100vw;
  background-image: url(${Background});
  background-repeat: no-repeat, repeat;
  background-size: cover;
  padding: 0 10vw;
  .logoWrapper {
    height: auto;
    margin-bottom: 64px;
    padding-bottom: 64px;
    border-bottom: 1px solid #ffffff4f;
    svg {
      height: 80px;
      width: auto;
      path {
        fill: ${(props) => props.theme.color.greyPalette.white};
      }
    }
  }
  .menuWrapper {
    height: auto;
    a {
        margin-right: 32px;
        text-decoration: none;
        .menuItem {
      width: 260px;
      height: auto;
      padding: 8px;
      border-radius: 20px;
      background-color: ${(props) => props.theme.color.greyPalette.white};
      cursor: pointer;
      opacity: 0.8;
      transition: ${(props) => props.theme.transition};
      overflow: auto;
      .topItem {
        height: 160px;
        padding: 32px;
        .iconWrapper {
          svg {
            height: 100%;
            width: auto;
          }
        }
      }
      .bottomItem {
        padding: 32px;
        border-radius: 0 0 20px 20px;
        background-color: ${(props) => props.theme.color.main.font};
        h1 {
          color: ${(props) => props.theme.color.greyPalette.white};
        }
      }
      
      &:hover {
        opacity: 1;
        transition: ${(props) => props.theme.transition};
        -webkit-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.3);
        .topItem {
          .iconWrapper {
            animation: rotate 1.6s ease-in-out infinite;
          }
        }
        .bottomItem {
          background-color: ${(props) => props.theme.color.main.blue};
        }
      }
    }
        &:last-child {
        margin-right: 0;
      }
    }
    
  }

  /*ANIMATIONS*/
  @keyframes rotate {
    0% {
      transform: scale(1) rotateZ(0);
    }
    50% {
      transform: scale(0.8) rotateZ(190deg);
    }

    100% {
      transform: scale(1) rotateZ(360deg);
    }
  }
`;

export class Menu extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledMenu flexDirection="column">
        <NanoFlex className="logoWrapper">
          <LicenseManagementLogo />
        </NanoFlex>
        <NanoFlex className="menuWrapper">
          {/* MENU ITEM */}
          <NavLink to="/rh">
            <NanoFlex className="menuItem" flexDirection="column">
              <NanoFlex className="topItem">
                <NanoFlex className="iconWrapper">
                  <IconMlog />
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="bottomItem">
                <Type.h1>
                  <b>CONSULTORIA</b>
                </Type.h1>
              </NanoFlex>
            </NanoFlex>
          </NavLink>
          {/* MENU ITEM */}
          <NavLink to="/adv">
            <NanoFlex className="menuItem" flexDirection="column">
              <NanoFlex className="topItem">
                <NanoFlex className="iconWrapper">
                  <IconMlog />
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="bottomItem">
                <Type.h1>
                  <b>ADVERTISING</b>
                </Type.h1>
              </NanoFlex>
            </NanoFlex>
          </NavLink>
          {/* MENU ITEM */}
          <NavLink to="/crm">
            <NanoFlex className="menuItem" flexDirection="column">
              <NanoFlex className="topItem">
                <NanoFlex className="iconWrapper">
                  <IconMlog />
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="bottomItem">
                <Type.h1>
                  <b>CRM</b>
                </Type.h1>
              </NanoFlex>
            </NanoFlex>
          </NavLink>
        </NanoFlex>
      </StyledMenu>
    );
  }
}
