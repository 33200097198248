import { BaseService } from "./BaseService";

export class AccountService extends BaseService {
  authenticate(username, password) {
    return this.request('Account', {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      })
    });
  }
  
  logout() {
    return this.request('Account', {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE"
    });
  }
  
  isCurrentTokenValid = () => {
    let currentToken = this.getToken();
    
    if (currentToken == null) {
      return false;
    }
    
    this
      .validateToken()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401)
          window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((responseToken) => {
        return currentToken === responseToken;
      });
  }
  
  validateToken() {
    return this.request('Account', {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET"
    });
  }
  
  invalidateToken() {
    return this.removeToken();
  }
  
  // forgotPassword(email) {
  //     return this.request(`Account/ForgotPassword?Email=${email}&From=1`, {
  //         headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //         },
  //         method: "GET",
  //     });
  // }
  
  // changePassword(token, password) {
  //     return this.request(`Account/ChangePassword`, {
  //         headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //         },
  //         method: "PUT",
  //         body: JSON.stringify({
  //             token,
  //             password,
  //         }),
  //     });
  // }
}

const accountService = new AccountService();

export default accountService;