import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";

const StyledLayout = styled(NanoFlex)``;

export class Layout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <StyledLayout flexDirection={"column"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"flex-start"}>
        {React.cloneElement(this.props.children, { render: this.props.render })}
      </StyledLayout>
    );
  }
}
