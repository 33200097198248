import React from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";

const ButtonCTAStyled = styled(NanoFlex)`
  height: auto;
user-select:none;
  .ctaWrapper {
    width: ${(props) => (props.smallCta ? "auto" : "100%")};
    height: ${(props) => (props.smallCta ? "42px" : "46px")};
    padding: 6px 24px;
    user-select: none;
    font-family: ${(props) => props.theme.font};
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "")};
    text-transform: uppercase;
    border-radius: 50px;
    border: none;
    border: 0;
    background-color: ${(props) => (props.darkBlue ? props.theme.color.main.darkBlue : props.orange ? props.theme.color.main.orange : props.disabled ? props.theme.color.greyPalette.lightGrey : props.theme.color.main.blue)};
    transition: ${(props) => props.theme.transition};
    text-transform: uppercase;
    color: ${(props) => props.theme.color.greyPalette.white};
    font-size: 18px;
    font-weight: bold;
    &:hover {
      opacity: 0.8;
      transition: ${(props) => props.theme.transition};
    }
  }
`;

const ButtonCTA = (props) => {
  return (
    <ButtonCTAStyled className="cta" darkBlue={props.darkBlue} orange={props.orange} disabled={props.disabled} smallCta={props.smallCta}>
      <button className="ctaWrapper" type={props.type} onClick={() => props.clickAction(props.id)}>
        {props.label}
      </button>
    </ButtonCTAStyled>
  );
};

// Set default props
ButtonCTA.defaultProps = {
  id: 0,
  label: "",
  type: "submit",
  darkBlue: false,
  clickAction: () => null,
};

export default ButtonCTA;

/*
  Doc
  import ButtonCTA from './Buttons/ButtonCTA.js';
  
  <ButtonCTA label="Contacte-nos"/>
  <ButtonCTA darkBlue label="Contacte-nos"/>
  <ButtonCTA orange label="Contacte-nos"/>
  <ButtonCTA smallCta orange label="Contacte-nos"/>

  */
