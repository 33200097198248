import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

// SERVICES
import accountService from "../../../services/AccountService";

// COMPONENTS
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import Input from "../../Elements/Forms/Input.js";
import Loading from "../../Elements/Loadings/Loading.js";

// IMAGES
import Background from "../../Global/images/bckLogin.svg";
import { ReactComponent as MlogMedialog } from "../../Global/images/mlogMedialogLogo.svg";
import { ReactComponent as LicenseManagementLogo } from "../../Global/images/licenseManagementLogo.svg";


const StyledLogin = styled(NanoFlex)`
  height: 100vh;
  width: 100vw;
  background-image: url(${Background});
  background-repeat: no-repeat, repeat;
  background-size: cover;
  .asideLogin {
    .logoWrapper {
      svg {
        width: 40%;
        height: auto;
      }
    }
    .loginFormWrapper {
      width: 50%;
      min-width: 480px;
      height: auto;
      background-color: ${(props) => props.theme.color.greyPalette.white};
      padding: 50px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      .LicenseManagementLogoWrapper {
        padding-bottom: 25px;
        border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lighterGrey};
        svg {
          max-width: 200px;
        }
      }

      .loginWrapper {
        .headerForm {
          height: auto;
          h2 {
            b {
              color: ${(props) => props.theme.color.greyPalette.white};
            }
          }
        }
        .formWrapper {
          height: auto;
          .formContainer {
            .inputContainer {
              margin-bottom: 8px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .submitContainer {
            margin-top: 24px;
          }
          .passwordForgotContainer {
            margin-top: 24px;
            p {
              text-align: center;
              cursor: pointer;
            }

            &:hover {
              p {
                color: ${(props) => props.theme.color.main.blue};
              }
            }
          }
        }
      }
    }
  }
  .loadingContainer {
    background: rgba(250, 250, 250, 0.4);
    overflow: hidden;
  }
  @media only screen and (max-width: 1280px) {
    .loginContainer {
      flex-direction: column;
      .rightAside {
        display: none;
      }
      .leftAside {
        justify-content: center;
      }
    }
  }
`;

export class Login extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      username: "",
      password: "",
      error: false,
      loading: false
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  login = (event) => {
    // Prevent page refresh
    event.preventDefault();

    this.setState({ 
      ...this.state,
      isLoading: true
    });

    accountService
      .authenticate(this.state.username, this.state.password)
      .then((response) => {
        if (!response.ok || response.status === 204) {
          this.setState({ 
            ...this.state,
            error: true, 
            isLoading: false 
          });
        } else {
          if (response.ok) {
            return response.json();
          }
        }
      })
      .then((data) => {
        if (data) {
          // Set User
          accountService.setToken(data);
          
          // Redirect to Homepage
          if (this.props.render.history) {
            this.props.render.history.push("/");
          }
        }
      });
  };

  render() {
    return (
      <StyledLogin flexDirection="column" justifyContent="space-between">
        {this.state.isLoading && (
          <NanoFlex className="loadingContainer">
            <Loading />
          </NanoFlex>
        )}
        {!this.state.isLoading && (
          <NanoFlex className="loginContainer">
            <NanoFlex className="asideLogin rightAside">
              <NanoFlex className="logoWrapper">
                <MlogMedialog />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="asideLogin leftAside" justifyContent="flex-start">
              <NanoFlex className="loginFormWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="LicenseManagementLogoWrapper" justifyContent="flex-start" alignItems="flex-start">
                  <LicenseManagementLogo />
                </NanoFlex>

                <NanoFlex className="loginWrapper" flexDirection="column" justifyContent="space-between">
                  <NanoFlex className="headerForm">
                    <Type.h2>
                      <b>INICIAR SESSÃO</b>
                    </Type.h2>
                  </NanoFlex>
                  <NanoFlex className="formWrapper" flexDirection="column">
                    <NanoFlex className="formContainer" flexDirection="column">
                      <Input 
                        id={0}
                        title="Utilizador"
                        placeholder="Utilizador"
                        error={this.state.error}
                        onChangeAction={(value) => this.setState({ username: value })}
                      />
                      <Input
                        id={1}
                        type={this.state.showPassword ? "text" : "password"}
                        title="Password"
                        placeholder="Password"
                        eye={true}
                        showPassword={this.state.showPassword}
                        error={this.state.error}
                        onClickEyeToggle={() => this.setState({ showPassword: !this.state.showPassword })}
                        onChangeAction={(value) => this.setState({ password: value })}
                      />
                    </NanoFlex>
                    <NanoFlex className="submitContainer" onClick={this.login}>
                      <ButtonCTA label="Iniciar Sessão" />
                    </NanoFlex>

                    <NanoFlex className="passwordForgotContainer">
                      <Type.p>Esqueceu-se da password?</Type.p>
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledLogin>
    );
  }
}
