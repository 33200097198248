import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import RenderRoute from "./components/Global/RenderRoute.js";
import PrivateRoute from "./components/Global/PrivateRoute.js";

// SERVICES
import accountService from "./services/AccountService";

//Layout
import { Layout } from "./components/Views/Shared/Layout.js";

//Views
import { Guidelines } from "./components/Views/Guidelines/Guidelines.js";
import { Home } from "./components/Views/Home/Home.js";
import { Menu } from "./components/Views/Home/Menu.js";
import { Login } from "./components/Views/Account/Login.js";

/* Themes */
import defaultTheme from "./themes/default.json";

import "./styles/reset.scss";
import "./styles/config.scss";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendToLogin: false,
      sendToRoot: false
    };
  }

  componentDidMount() {
    window.addEventListener("sendToLogin", this.sendToLoginEvent, false);

    if (!this.state.sendToLogin) {
      this.validateCurrentToken();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sendToLogin !== this.state.sendToLogin) {
      this.setState({ sendToLogin: false });
    }

    if (prevState.sendToRoot !== this.state.sendToRoot) {
      this.setState({ sendToRoot: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("sendToLogin", this.sendToLoginEvent, false);
  }

  sendToLoginEvent = () => {
    this.setState({ sendToLogin: true });
  };

  validateCurrentToken = () => {
    let currentToken = accountService.getToken();
    
    if (currentToken == null) {
      return false;
    }
    
    accountService
      .validateToken()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseToken) => {       
        let isValid = currentToken === responseToken;

        if (!isValid) {
          this.setState({ sendToLogin: true });
        }
        else if (window.location.pathname === '/login') {
          this.setState({ sendToRoot: true });
        }
      });
  }

  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Switch>
            {this.state.sendToLogin && <Redirect to="/login" />}
            {this.state.sendToRoot && <Redirect to="/" />}

            {/* LOGIN */}
            <RenderRoute exact path="/login">
              <Layout>
                <Login />
              </Layout>
            </RenderRoute>
            {/* LOGIN */}

            {/* CONSULTORIA */}
            <PrivateRoute exact path="/">
              <Layout>
                <Menu />
              </Layout>
            </PrivateRoute>
            {/* CONSULTORIA */}

            {/* CONSULTORIA */}
            <PrivateRoute exact path="/rh">
              <Layout>
                <Home versionId={1} />
              </Layout>
            </PrivateRoute>
            {/* CONSULTORIA */}
            
            {/* ADVERTISING */}
            <PrivateRoute exact path="/adv">
              <Layout>
                <Home versionId={2} />
              </Layout>
            </PrivateRoute>
            {/* ADVERTISING */}

            {/* CRM */}
            <PrivateRoute exact path="/crm">
              <Layout>
                <Home versionId={3} />
              </Layout>
            </PrivateRoute>
            {/* ADVERTISING */}

            {window.location.hostname === "localhost" && (
              <RenderRoute exact path="/guidelines">
                <Guidelines />
              </RenderRoute>
            )}
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
