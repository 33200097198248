import React from "react";
import styled from "styled-components";

import NanoFlex from "../../Global/NanoFlex.js";

const StyledLoading = styled(NanoFlex)`
  .loadingWrapper {
    animation: rotate 1.6s ease-in-out infinite;
  }

  /*ANIMATIONS*/

  @keyframes rotate {
    0% {
      transform: scale(0.8) rotateZ(0);
    }
    50% {
      transform: scale(1) rotateZ(190deg);
    }

    100% {
      transform: scale(0) rotateZ(360deg);
    }
  }
`;

const Loading = (props) => {
  return (
    <StyledLoading>
      <NanoFlex>
        <div className="loadingWrapper">
          <svg width="109" height="115" viewBox="0 0 109 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.42977 85.2246C0.46266 83.4938 -0.029993 81.5396 0.00141291 79.5589L0.671152 33.5751C0.700749 31.594 1.25348 29.6556 2.2737 27.9549C3.29392 26.2543 4.74564 24.8515 6.48281 23.8875L46.7323 1.43382C48.4672 0.468658 50.4261 -0.0254385 52.4129 0.00100858C54.3997 0.0274557 56.3447 0.573468 58.0531 1.58447L72.4198 10.1115L63.7321 20.9099L52.2252 14.0801L14.369 35.1987L13.7406 78.3487L50.994 100.46L88.8592 79.3472L89.1997 54.3596L95.2035 55.3036L103.041 45.5429L102.559 80.9582C102.529 82.9367 101.979 84.8728 100.963 86.5729C99.9464 88.2729 98.5004 89.6774 96.7687 90.6458L56.4976 113.1C54.7574 114.06 52.7955 114.552 50.8062 114.525C48.8169 114.499 46.8691 113.955 45.1552 112.949L5.51057 89.4187C3.8043 88.402 2.39687 86.9555 1.42977 85.2246Z"
              fill="#474747"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M78.8698 14.1104L96.4476 16.9969L103.063 33.4989L92.1253 47.1144L74.546 44.2236L67.9246 27.7078L78.8698 14.1104Z" fill="#0B679F" />
          </svg>
        </div>
      </NanoFlex>
    </StyledLoading>
  );
};

export default Loading;

// --- // Documentation // --- //
/*

import Loading from './Loading.js';

<Loading/>
*/
