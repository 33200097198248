import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

const StyledLogLine = styled(NanoFlex)`
  height: auto;
  border-bottom: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
  padding-top: 8px;
  padding-bottom: 8px;
  .logLine {
    height: auto;
    .row {
        margin-bottom: 4px;
      .info {
        width: auto;
        margin-right: 8px;
        p {
            em {
                font-size: 12px;
                color: ${(props) => props.theme.color.main.red};
                font-weight: normal;
            }
        }
        h6 {
          em {
            color: ${(props) => props.theme.color.greyPalette.grey};
          }
        }
        &:after {
          content: "|";
          display: flex;
          font-size: 10px;
          color: ${(props) => props.theme.color.greyPalette.grey};
          margin-left: 8px;
        }
        &:last-child {
          margin-right: 0;
          &:after {
            display: none;
          }
        }
      }
      &:last-child {
          margin-bottom: 0;
      }
    }
  }
  &:first-child {
      padding-top: 0;
  }
  &:last-child {
      border-bottom: 0;
  }
`;

class LogLine extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledLogLine>
        <NanoFlex className="logLine" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="row" justifyContent="flex-start" alignItems="flex-start">
            {/* DATE */}
            <NanoFlex className="info">
              <Type.h6>{this.props.date}</Type.h6>
            </NanoFlex>
            {/* HOUR */}
            <NanoFlex className="info">
              <Type.h6>{this.props.hour}</Type.h6>
            </NanoFlex>
            {/* CLIENT ID */}
            <NanoFlex className="info">
              <Type.h6>
                <em>ID Cliente:</em> {this.props.clientID}
              </Type.h6>
            </NanoFlex>
            {/* USER ID */}
            <NanoFlex className="info">
              <Type.h6>
                <em>ID Utilizador:</em> {this.props.userID}
              </Type.h6>
            </NanoFlex>
            {/* PLATFORM ID */}
            <NanoFlex className="info">
              <Type.h6>
                <em>ID Plataforma:</em> {this.props.platformID}
              </Type.h6>
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="row" justifyContent="flex-start" alignItems="flex-start">
            {/* METHOD */}
            <NanoFlex className="info" justifyContent="flex-start">
              <Type.p>{this.props.method}</Type.p>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="row" justifyContent="flex-start" alignItems="flex-start">
            {/* ERROR */}
            <NanoFlex className="info" justifyContent="flex-start">
              <Type.p><em>Erro:</em> {this.props.error}</Type.p>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledLogLine>
    );
  }
}

export default LogLine;
