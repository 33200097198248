import React from "react";
import { Route, Redirect } from "react-router-dom";
import accountService from "../../services/AccountService.js";

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        accountService.getToken() != null 
          ? (React.cloneElement(children, { render: props }))
          : (<Redirect to={{ pathname: "/login", state: { from: props.location }}} />)
      }
    />
  );
};

export default PrivateRoute;