import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled, { css } from "styled-components";
import Settings from "../../Global/Settings.json";
import moment from "moment";

import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

import { ReactComponent as ArrowRight } from "../../Global/icons/arrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../Global/icons/arrowLeft.svg";

const multiple = 8;
const minTouchBlock = multiple * 4; // 24
const weekendIndexArrays = {
  saturday: [5, 12, 19, 26, 33, 40],
  sunday: [6, 13, 20, 27, 34, 41],
};
const allWeekendIndex = weekendIndexArrays.saturday.concat(weekendIndexArrays.sunday);
const dayFormat = Settings.moment.date;
const justDayNumber = Settings.moment.day;
const monthFormat = Settings.moment.month;
const monthLabelFormat = Settings.moment.monthLabel;
const yearFormat = Settings.moment.year;
const comparisonMonthYearFormat = Settings.moment.monthYear;
const now = moment();
const currentDay = now.format(dayFormat);
const startOfTheYear = moment(currentDay, dayFormat).startOf("years").format(dayFormat);
const inBetweenDefaultList = new Array(31).fill(1).map((v, i) => v + i);

let lastResultinBetweenSelectionMonths = {
  startMonthSelected: null,
  endMonthSelected: null,
  inBetweenSelectionMonths: [],
};

export const dateComparison = (dateOne, dateTwo) => {
  if (!dateOne || !dateTwo) return false;

  // Day
  const dateOneDay = parseInt(moment(dateOne, dayFormat).format(justDayNumber));
  const dateTwoDay = parseInt(moment(dateTwo, dayFormat).format(justDayNumber));

  const dayBoolOutput = dateOneDay > dateTwoDay;

  // Month
  const dateOneMonth = parseInt(moment(dateOne, dayFormat).format(monthFormat));
  const dateTwoMonth = parseInt(moment(dateTwo, dayFormat).format(monthFormat));

  const monthOrSameBoolOutput = dateOneMonth >= dateTwoMonth;
  const monthBoolOutput = dateOneMonth > dateTwoMonth;

  // Year
  const dateOneYear = parseInt(moment(dateOne, dayFormat).format(yearFormat));
  const dateTwoYear = parseInt(moment(dateTwo, dayFormat).format(yearFormat));

  const yearOrSameBoolOutput = dateOneYear >= dateTwoYear;
  const yearBoolOutput = dateOneYear > dateTwoYear;

  return (dayBoolOutput && monthOrSameBoolOutput && yearOrSameBoolOutput) || (monthBoolOutput && yearOrSameBoolOutput) || yearBoolOutput;
};

const StyledBlockBox = styled.div`
  width: ${minTouchBlock + "px"};
  height: ${minTouchBlock + "px"};
  cursor: pointer;

  background-color: ${(props) => {
    if (!props.content) return props.theme.color.main.white;

    if (props.selected || props.inBetweenSelection) return props.theme.color.main.blue;

    if (props.alternative) {
      return props.theme.color.greyPalette.lightGrey;
    } else {
      return props.theme.color.greyPalette.white;
    }
  }};

  p {
    color: ${(props) => {
      if (props.selected || props.inBetweenSelection) return props.theme.color.greyPalette.white;
    }};
  }

  opacity: ${(props) => (props.inBetweenSelection ? 0.6 : 1)};

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  &:hover {
    opacity: 0.7;
  }
`;

const StyledDateRangePicker = styled(NanoFlex)`
  width: 280px;
  background-color: ${(props) => props.theme.color.main.white};
  padding: ${multiple + "px"};
  /* min-height: 272px; */
  align-items: stretch;
  align-content: stretch;
  user-select: none;
  .dateRangePicker {
    .datePicker {
      align-items: flex-start;
      height: auto;
    }
  }
  .topWrapper {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
    background-color: ${(props) => props.theme.color.greyPalette.lightGrey};
    height: 32px;
    .blockBox {
      width: ${multiple * 4 + "px"};
      height: 32px;
      background-color: ${(props) => props.theme.color.greyPalette.lightGrey};
      p {
        line-height: initial;
        svg {
          height: 10px;
          width: auto;
          path {
            fill: ${(props) => props.theme.color.main.blue};
          }
        }
      }
    }
  }
  .monthWrapper {
    width: 60%;
    height: 32px;
  }
  .date-titles {
    height: 32px;
  }
  .yearWrapper {
    width: 40%;
    height: 32px;
  }

  .date-table {
    .dateTableWrapper {
      height: auto;
      justify-content: flex-start;
    }
  }

  .searchListBlock {
    height: auto;
    justify-content: flex-start;
  }

  .dateTableWrapper {
    .week-table {
      background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
      border-right: 1px solid ${(props) => props.theme.color.greyPalette.lightGrey};
      margin-right: 4px;
      .weekHeader {
        width: 32px;
        height: 32px;
        p {
          color: ${(props) => props.theme.color.greyPalette.grey};
        }
      }
      .weekNumberList {
        .weekNumber {
          width: 32px;
          height: 32px;
          p {
            color: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
  }

  &:last-child {
    padding-left: 0;
  }

  /* @media only screen and (max-width: 800px) {
    width: 276px;
  } */
`;

const BlockBox = (props) => (
  <StyledBlockBox className="blockBox" onClick={() => (props.blockBoxAction ? props.blockBoxAction(props.data.display) : null)} alternative={props.alternative} content={props.data.display} selected={props.selected} inBetweenSelection={props.inBetweenSelection} disabled={props.data.disabled}>
    <NanoFlex>
      {props.bold ? (
        <Type.p white={props.selected || props.inBetweenSelection}>
          <b>{props.data.display}</b>
        </Type.p>
      ) : (
        <Type.p white={props.selected || props.inBetweenSelection}>{props.data.display}</Type.p>
      )}
    </NanoFlex>
  </StyledBlockBox>
);

// Set default props
BlockBox.defaultProps = {
  data: null,
  bold: false,
};

// --- // Documentation // --- //
/*
const mockData = {};

<BlockBox
    data={mockData}
    content={'>'}
    blockBoxAction={this.goOneMonthForward}
/>
*/

const BlockBoxList = (props) =>
  !props.data
    ? null
    : props.data.map((data, dataIndex) => {
        return (
          <BlockBox
            key={dataIndex}
            data={data}
            alternative={props.alternativeList ? props.alternativeList.includes(dataIndex) : false}
            bold={props.bold}
            selected={props.selectedList ? props.selectedList.includes(data.display) : false}
            inBetweenSelection={props.inBetweenSelectionList ? props.inBetweenSelectionList.includes("All") || props.inBetweenSelectionList.includes(data.display) : false}
            blockBoxAction={props.blockBoxListAction}
          />
        );
      });

// --- // Documentation // --- //
/*
const mockDataObj = {
    content: ''
};
const mockDataList = new Array(31).fill(mockDataObj);

<BlockBoxList
    data={mockDataList}
    blockBoxListAction={(clickedObj) => console.log(clickedObj)}
/>
*/

class DateRangePicker extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: false,

      mainDate: this.props.secondDefaultMonth
        ? moment(this.props.mainViewMonth ?? startOfTheYear, dayFormat)
            .clone()
            .startOf("month")
            .add(1, "months")
            .format(dayFormat)
        : moment(this.props.mainViewMonth ?? startOfTheYear, dayFormat)
            .clone()
            .startOf("month")
            .format(dayFormat),

      startSelectedDate: this.props.startDate && this.props.multipleDateRangePickers ? this.props.startDate : null,
      endSelectedDate: this.props.endDate && this.props.multipleDateRangePickers ? this.props.endDate : null,
      onClickCounter: this.props.onClickCounter && this.props.multipleDateRangePickers ? this.props.onClickCounter : 0,

      selectedList: [],
      inBetweenSelection: [],

      minDate: this.props.minDate ? this.props.minDate : null,
      maxDate: this.props.maxDate ? this.props.maxDate : null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.validateMinMaxDate();
    this.updateStateList(this.state.mainDate, this.props.startDate, this.props.endDate);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mainDate !== this.state.mainDate || prevState.startSelectedDate !== this.state.startSelectedDate || prevState.endSelectedDate !== this.state.endSelectedDate || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
      if (this.props.multipleDateRangePickers) {
        this.updateStateList(this.state.mainDate, this.props.startDate, this.props.endDate);
      } else {
        this.updateStateList(this.state.mainDate, this.state.startSelectedDate, this.state.endSelectedDate);
      }

      this.validateMinMaxDate();
    }

    if (prevProps.mainViewMonth !== this.props.mainViewMonth && this.props.mainViewMonth) {
      const mainMonthSelected = moment(this.props.mainViewMonth, dayFormat).startOf("month").format(dayFormat);
      this.setState({ mainDate: mainMonthSelected });
    }

    if (prevProps.mainViewMonth !== this.props.mainViewMonth && prevProps.endDate !== this.props.endDate && this.props.endDate !== null && this.props.startDate !== null && this.props.secondDefaultMonth) {
      const startMonthSelected = moment(this.props.startDate, dayFormat).startOf("month").format(dayFormat);
      const endMonthSelected = moment(this.props.endDate, dayFormat).startOf("month").format(dayFormat);
      const isSameMonthAndYear = startMonthSelected === endMonthSelected;
      const startMonthSelectedPlusOne = moment(this.props.startDate, dayFormat).startOf("month").add(1, "months").format(dayFormat);
      const endMonthSelectedLessOne = moment(this.props.endDate, dayFormat).startOf("month").subtract(1, "months").format(dayFormat);
      if (isSameMonthAndYear) this.setState({ mainDate: this.props.secondDefaultMonth ? startMonthSelectedPlusOne : endMonthSelectedLessOne });
    }

    if (prevProps.mainViewMonth !== this.props.mainViewMonth && this.props.mainViewMonth === null && this.props.startDate !== null && this.props.secondDefaultMonth) {
      this.setState({ mainDate: moment(this.props.startDate, dayFormat).startOf("month").add(1, "months").format(dayFormat) });
    }

    if (prevProps.minDate !== this.props.minDate) {
      this.setState({ minDate: this.props.minDate });
    }

    if (prevProps.maxDate !== this.props.maxDate) {
      this.setState({ maxDate: this.props.maxDate });
    }

    if (prevState.minDate !== this.state.minDate || prevState.maxDate !== this.state.maxDate) {
      this.validateMinMaxDate();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  goOneMonthBack = () => {
    this.setState({ mainDate: moment(this.state.mainDate, dayFormat).subtract(1, "months").format(dayFormat) });
  };

  goOneMonthForward = () => {
    this.setState({ mainDate: moment(this.state.mainDate, dayFormat).add(1, "months").format(dayFormat) });
  };

  goOneYearBack = () => {
    this.setState({ mainDate: moment(this.state.mainDate, dayFormat).subtract(1, "years").format(dayFormat) });
  };

  goOneYearForward = () => {
    this.setState({ mainDate: moment(this.state.mainDate, dayFormat).add(1, "years").format(dayFormat) });
  };

  weekdayPosition = (date) => {
    // ISO Day of Week is iqual to the index of this array =>
    // [null, "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    const iso = moment(date, dayFormat).isoWeekday() - 1; //.isoWeekday();
    if (iso === 7) {
      return 0;
    } else {
      return iso;
    }
  };

  addBlankBlock = (date, list) => {
    const blankList = new Array(this.weekdayPosition(date)).fill({ display: "", disabled: false });

    return [...blankList, ...list];
  };

  createMainArray = (date) => {
    var momentDate = moment(date, dayFormat);
    const daysInMonth = momentDate.daysInMonth();
    const emptyDataList = new Array(daysInMonth).fill("");

    var results = emptyDataList.map((v, i) => {
      var day = (v = i + 1);
      var newDate = momentDate.set("date", day);
      return {
        display: day,
        disabled: (this.state.minDate && moment(newDate).startOf("day") < moment(this.state.minDate).startOf("day")) || (this.state.maxDate && moment(newDate).startOf("day") > moment(this.state.maxDate).startOf("day")),
      };
    });
    return results;
  };

  selectDays = (day, date) => {
    if (!day) return;

    const outputDate = moment(date, dayFormat)
      .add(day - 1, "days")
      .format(dayFormat);

    let immutableState = JSON.parse(JSON.stringify(this.state));

    if (this.props.multipleDateRangePickers) {
      immutableState.startSelectedDate = this.props.startDate;
      immutableState.endSelectedDate = this.props.endDate;
      immutableState.onClickCounter = this.props.onClickCounter;
    }

    // Deselect
    const shouldDeselectStartDate = outputDate === immutableState.startSelectedDate;
    const shouldDeselectEndDate = outputDate === immutableState.endSelectedDate;
    if (shouldDeselectStartDate || shouldDeselectEndDate) {
      this.deselectDays(shouldDeselectStartDate, shouldDeselectEndDate);
      return;
    }

    let output = null;

    // First range selection
    const firstRangeSelection = this.props.multipleDateRangePickers ? this.props.onClickCounter === 0 || this.props.onClickCounter === 1 : immutableState.onClickCounter === 0 || immutableState.onClickCounter === 1;

    if (firstRangeSelection) {
      output = {
        startSelectedDate: immutableState.onClickCounter === 0 ? outputDate : immutableState.startSelectedDate,
        endSelectedDate: immutableState.onClickCounter === 1 ? outputDate : immutableState.endSelectedDate,
        onClickCounter: immutableState.onClickCounter + 1,
      };

      // Reselect dates, after range selection
    } else {
      output = {
        startSelectedDate: outputDate > immutableState.startSelectedDate ? immutableState.startSelectedDate : outputDate,
        endSelectedDate: outputDate > immutableState.startSelectedDate ? outputDate : immutableState.endSelectedDate,
        onClickCounter: 2,
      };
    }

    // Swap startSelectedDate and endSelectedDate if they are out of order
    if (dateComparison(output.startSelectedDate, output.endSelectedDate)) {
      const prevImmutableOutput = JSON.parse(JSON.stringify(output));
      output.startSelectedDate = prevImmutableOutput.endSelectedDate;
      output.endSelectedDate = prevImmutableOutput.startSelectedDate;
    }

    if (this.props.multipleDateRangePickers) {
      this.props.onUpdateClickCounter(output.onClickCounter);
      this.props.onDateChange(output.startSelectedDate, output.endSelectedDate);
    } else {
      this.setState(output);
    }
  };

  deselectDays = (shouldDeselectStartDate, shouldDeselectEndDate) => {
    if (shouldDeselectStartDate) {
      if (this.props.multipleDateRangePickers) {
        this.props.onUpdateClickCounter(0);
        this.props.onDateChange(null, null);
      } else {
        this.setState({
          startSelectedDate: null,
          endSelectedDate: null,
          onClickCounter: 0,
        });
      }
    }

    if (shouldDeselectEndDate) {
      if (this.props.multipleDateRangePickers) {
        this.props.onUpdateClickCounter(1);
        this.props.onDateChange(this.props.startDate, null);
      } else {
        this.setState({
          startSelectedDate: this.state.startSelectedDate,
          endSelectedDate: null,
          onClickCounter: 1,
        });
      }
    }
  };

  updateMonthLabel = (date) => {
    return moment(date, dayFormat).locale(this.getMomentLanguage()).format(monthLabelFormat);
  };

  updateYearLabel = (date) => {
    return moment(date, dayFormat).format(yearFormat);
  };

  isAMonthInBetweenSelection = (mainDate, startDate, endDate) => {
    let inBetweenSelectionMonths = [];
    let output = false;

    const mainMonthSelected = moment(mainDate, dayFormat).format(comparisonMonthYearFormat);
    const startMonthSelected = moment(startDate, dayFormat).format(comparisonMonthYearFormat);
    const endMonthSelected = moment(endDate, dayFormat).format(comparisonMonthYearFormat);

    if (lastResultinBetweenSelectionMonths.startMonthSelected !== startMonthSelected || lastResultinBetweenSelectionMonths.endMonthSelected !== endMonthSelected) {
      const startDateDayOneMonth = moment(startDate, dayFormat).startOf("month").format(dayFormat);
      const endDateDayOneMonth = moment(endDate, dayFormat).startOf("month").format(dayFormat);

      const loopPushInBetweenSelectionMonthsList = (currentDateLoop) => {
        if (inBetweenSelectionMonths.length === 101) return;
        if (currentDateLoop === endDateDayOneMonth) return;

        const value = moment(currentDateLoop, dayFormat).add(1, "months").format(dayFormat);
        const comparisonValue = moment(currentDateLoop, dayFormat).add(1, "months").format(comparisonMonthYearFormat);

        inBetweenSelectionMonths.push(comparisonValue);

        loopPushInBetweenSelectionMonthsList(value);
      };

      loopPushInBetweenSelectionMonthsList(startDateDayOneMonth);

      inBetweenSelectionMonths.pop();

      // Save output result
      lastResultinBetweenSelectionMonths = {
        startMonthSelected,
        endMonthSelected,
        inBetweenSelectionMonths,
      };
    } else {
      inBetweenSelectionMonths = lastResultinBetweenSelectionMonths.inBetweenSelectionMonths;
    }

    output = inBetweenSelectionMonths.includes(mainMonthSelected);

    return output;
  };

  isSameMonthAndYear = (mainDate, date) => {
    return moment(date, dayFormat).format(comparisonMonthYearFormat) === moment(mainDate, dayFormat).format(comparisonMonthYearFormat);
  };

  getJustDayNumber = (date) => {
    return parseInt(moment(date, dayFormat).format(justDayNumber));
  };

  createSelectedList = (mainDate, startDate, endDate) => {
    if (!mainDate || !startDate) return [];

    const isStartDateOnCurrentMonth = this.isSameMonthAndYear(mainDate, startDate);
    const isEndDateOnCurrentMonth = this.isSameMonthAndYear(mainDate, endDate);

    let output = [];

    if (isStartDateOnCurrentMonth) output.push(this.getJustDayNumber(startDate));
    if (isEndDateOnCurrentMonth) output.push(this.getJustDayNumber(endDate));

    return output;
  };

  createInBetweenSelectionList = (mainDate, startDate, endDate) => {
    if (!mainDate || !startDate || !endDate) return [];

    const isAMonthInBetweenSelection = this.isAMonthInBetweenSelection(mainDate, startDate, endDate);
    if (isAMonthInBetweenSelection) return ["All"];

    const isStartDateOnCurrentMonth = this.isSameMonthAndYear(mainDate, startDate);
    const isEndDateOnCurrentMonth = this.isSameMonthAndYear(mainDate, endDate);

    const startDayNumber = this.getJustDayNumber(startDate);
    const endDayNumber = this.getJustDayNumber(endDate);

    let output = [];

    if (isStartDateOnCurrentMonth && isEndDateOnCurrentMonth) output = inBetweenDefaultList.filter((num) => num > startDayNumber && num < endDayNumber);
    if (isStartDateOnCurrentMonth && !isEndDateOnCurrentMonth) output = inBetweenDefaultList.filter((num) => num > startDayNumber);
    if (!isStartDateOnCurrentMonth && isEndDateOnCurrentMonth) output = inBetweenDefaultList.filter((num) => num < endDayNumber);

    return output;
  };

  updateStateList = (mainDate, startDate, endDate) => {
    this.setState({
      selectedList: this.createSelectedList(mainDate, startDate, endDate),
      inBetweenSelection: this.createInBetweenSelectionList(mainDate, startDate, endDate),
    });
  };

  validateMinMaxDate = () => {
    let hasChanged = false;
    let { mainDate, startSelectedDate } = { ...this.state };

    if (this.state.minDate && moment(mainDate, dayFormat) < moment(this.state.minDate).set("date", 1)) {
      mainDate = moment(this.state.minDate).set("date", 1).format(dayFormat);
      hasChanged = true;
    }

    if (this.state.maxDate && moment(mainDate, dayFormat) > moment(this.state.maxDate).set("date", 1)) {
      mainDate = moment(this.state.maxDate).set("date", 1).format(dayFormat);
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({ mainDate, startSelectedDate });
    }
  };

  isPrevMonthDisabled = () => {
    if (this.state.minDate) {
      let prevMonthDate = moment(this.state.mainDate, dayFormat).set("date", 1);
      return moment(prevMonthDate).startOf("day") < moment(this.state.minDate).startOf("day");
    }
    return false;
  };

  isNextMonthDisabled = () => {
    if (this.state.maxDate) {
      let nextMonthDate = moment(this.state.mainDate, dayFormat).set("date", 0).add("month", 1);
      return moment(nextMonthDate).startOf("day") > moment(this.state.maxDate).startOf("day");
    }
    return false;
  };

  isPrevYearDisabled = () => {
    if (this.state.minDate) {
      let prevYearDate = moment(this.state.mainDate, dayFormat).set("date", 31).set("month", 11).add("year", -1);
      return moment(prevYearDate).startOf("day") < moment(this.state.minDate).startOf("day");
    }
    return false;
  };

  isNextYearDisabled = () => {
    if (this.state.maxDate) {
      let nextYearDate = moment(this.state.mainDate, dayFormat).set("date", 0).set("month", 0).add("year", 1);
      return moment(nextYearDate).startOf("day") > moment(this.state.maxDate).startOf("day");
    }
    return false;
  };

  getWeekdays = () => {
    moment.locale(this.getMomentLanguage());
    let weekdaysShort = moment.weekdaysShort();
    weekdaysShort.push(weekdaysShort.splice(0, 1)[0]);
    return weekdaysShort;
  };

  getWeekNums = () => {
    let date = moment(this.state.mainDate, dayFormat);
    const firstDayOfMonth = date.set("date", 1);
    const numOfDays = firstDayOfMonth.daysInMonth();
    let weeks = new Set();
    for (let i = 0; i < numOfDays; i++) {
      const currentDay = moment(firstDayOfMonth, "YYYY-MM-DD").add(i, "days");
      weeks.add(currentDay.isoWeek());
    }
    return Array.from(weeks);
  };

  render() {
    const { mainDate, selectedList, inBetweenSelection } = this.state;

    return (
      <StyledDateRangePicker className="dateRangePickerWrapper">
        <NanoFlex className="dateRangePicker" flexDirection="column">
          <NanoFlex className="datePicker" justifyContent="space-between">
            <NanoFlex className="topWrapper monthWrapper">
              <BlockBox data={{ display: <ArrowLeft />, disabled: this.isPrevMonthDisabled() }} blockBoxAction={this.goOneMonthBack} />
              <NanoFlex className="date-titles">
                <Type.p>{this.updateMonthLabel(mainDate)}</Type.p>
              </NanoFlex>
              <BlockBox data={{ display: <ArrowRight />, disabled: this.isNextMonthDisabled() }} blockBoxAction={this.goOneMonthForward} />
            </NanoFlex>

            <NanoFlex className="topWrapper yearWrapper">
              <BlockBox data={{ display: <ArrowLeft />, disabled: this.isPrevYearDisabled() }} blockBoxAction={this.goOneYearBack} />
              <NanoFlex className="date-titles">
                <Type.p>{this.updateYearLabel(mainDate)}</Type.p>
              </NanoFlex>
              <BlockBox data={{ display: <ArrowRight />, disabled: this.isNextYearDisabled() }} blockBoxAction={this.goOneYearForward} />
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="dateTableWrapper" alignItems="flex-start">
            <NanoFlex className="week-table" flexDirection="column" justifyContent="flex-start" alignItems="stretch">
              <NanoFlex className="weekHeader">
                <Type.p></Type.p>
              </NanoFlex>
              <NanoFlex className="weekNumberList" flexDirection="column">
                {this.getWeekNums().map((v, i) => {
                  return (
                    <NanoFlex className="weekNumber" key={"WK_" + i}>
                      <Type.p>{v}</Type.p>
                    </NanoFlex>
                  );
                })}
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="date-table" flexDirection="column" justifyContent="flex-start" alignItems="stretch">
              <NanoFlex className="dateTableWrapper" justifyContent="flex-start">
                <BlockBoxList
                  data={this.getWeekdays().map((v) => {
                    return { display: v };
                  })}
                  directValue
                />
              </NanoFlex>

              <NanoFlex id={"searchListBlock"} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                <BlockBoxList data={this.addBlankBlock(mainDate, this.createMainArray(mainDate))} directValue selectedList={selectedList} inBetweenSelectionList={inBetweenSelection} alternativeList={allWeekendIndex} blockBoxListAction={(clickedDay) => this.selectDays(clickedDay, mainDate)} />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledDateRangePicker>
    );
  }
}

export default DateRangePicker;

// --- // Documentation // --- //
/*
import DateRangePicker from './../Nano/DateRangePicker.js';

<DateRangePicker/>

<DateRangePicker
    multipleDateRangePickers
    mainViewMonth={this.state.endDate}
    startDate={this.state.startDate}
    endDate={this.state.endDate}
    onClickCounter={this.state.onClickCounter}
    onUpdateClickCounter={(newCounter) => this.setState({ onClickCounter: newCounter })}
    onDateChange={(newStartDate, newEndDate) => this.updateMainState(newStartDate, newEndDate)}
/>

this.props.multipleDateRangePickers turns off startSelectedDate, endSelectedDate and onClickCounter (on this component state) and it starts using the same logic from the corresponding props (parent state)
*/
