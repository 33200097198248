import { BaseService } from "./BaseService";

export class ClientService extends BaseService {
    getAllClients() {
        return this.request(`Clients`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
        });
    }

    getClients(versionId, freeSearch) {
        let parameters = {};

        if (versionId !== null && versionId > 0) {
            parameters.versionId = versionId;
        }

        if (freeSearch?.length > 0) {
            parameters.freeSearch = freeSearch;
        }

        let endpoint = this.generateEndpointWithParameters("Clients", parameters);

        return this.request(endpoint, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "GET",
        });
    }

    getClient(id) {
        return this.request(`Clients/${id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "GET",
        });
    }

    saveClientData(client) {
        return this.request(`Clients`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(client)
        });
    }

    saveClientImg(id, formData) {
        return this.request(`Clients/${id}`, {
            headers: {
                Accept: "*/*"
            },
            method: "PUT",
            body: formData
        });
    }

    getClientsLogs(clientId) {
        return this.request(`Clients/GetClientLogs/${clientId}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            method: "GET"
        });
    }
}

const clientService = new ClientService();

export default clientService;
