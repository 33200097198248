import React from "react";
import { Component } from "react";
import styled from "styled-components";

import Type from "../../Global/Typography.js";
import NanoFlex from "../../Global/NanoFlex.js";

import { ReactComponent as Eye } from "./../../Global/icons/eye.svg";
import { ReactComponent as EyeClosed } from "./../../Global/icons/eyeClosed.svg";

const StyledInput = styled(NanoFlex)`
  height: auto;
  position: relative;

  .title {
    color: ${(props) => (props.error ? props.theme.color.main.red : props.theme.color.main.font)};
    margin-bottom: 4px;
  }
  .inputWrapper {
    font-family: ${(props) => props.theme.font};
  }
  input {
    border: 1px solid ${(props) => (props.error ? props.theme.color.main.red : props.theme.color.greyPalette.grey)};
    padding: 10px 16px;
    width: 100%;
    font-size: 16px;
    color: ${(props) => props.theme.color.main.font};
    font-family: ${(props) => props.theme.font};
    border-radius: 10px;
text-align: ${(props) => (props.alignRight ? "right" : "left")};
    &::placeholder {
      font-family: ${(props) => props.theme.font};
      color: ${(props) => props.theme.color.greyPalette.grey};
    }

    &:focus {
      border: 1px solid ${(props) => props.theme.color.main.font};
    }
  }
  .eye {
    position: absolute;
    bottom: 3px;
    right: 8px;
    height: 36px;
    width: 40px;
    user-select: none;
    svg {
      cursor: pointer;
      path {
        fill: ${(props) => props.theme.color.greyPalette.grey};
      }
    }
  }
`;

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEye: false
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.inputRef.current.value = this.props.defaultValue;
    }
  }

  toggleEye = () => {
    this.setState({ showEye: !this.state.showEye });
  };

  render() {
    return (
      <StyledInput className="inputContainer" error={this.props.error} flexDirection="column" justifyContent="flex-start" alignItems="flex-start" small={this.props.small} alignRight={this.props.alignRight}>
        {this.props.title && <Type.p className="title">{this.props.title}</Type.p>}

        <NanoFlex className="inputWrapper">
          <input
            ref={this.inputRef}
            type={this.props.eye ? (this.state.showEye ? "text" : "password") : "text"}
            placeholder={this.props.placeholder}
            {...(!this.props.forceValue ? { defaultValue: this.props.defaultValue } : null)}
            {...(this.props.forceValue ? { value: this.props.defaultValue } : null)}
            onChange={(e) => {
              if (this.props.onChangeAction) {
                this.props.onChangeAction(e.target.value);
              }
            }}
            onKeyPress={(e) => {
              if (this.props.onKeyPress) {
                this.props.onKeyPress(e);
              }
            }}
            onKeyDown={(e) => {
              if (this.props.onKeyDown) {
                return this.props.onKeyDown(e);
              }
            }}
            name={this.props.name ?? Math.random().toString(36).substring(7)}
            {...(!this.props.enableAutoComplete ? { autoComplete: /chrome/.test(navigator.userAgent.toLowerCase()) ? "new-password" : "off" } : null)}
          />
          {this.props.eye && this.state.showEye && (
            <NanoFlex className="eye" onClick={this.toggleEye}>
              <Eye />
            </NanoFlex>
          )}
          {this.props.eye && !this.state.showEye && (
            <NanoFlex className="eye" onClick={this.toggleEye}>
              <EyeClosed />
            </NanoFlex>
          )}
        </NanoFlex>

        {this.props.message && <Type.h6 className="message">{this.props.message}</Type.h6>}
      </StyledInput>
    );
  }
}

// Set default props
Input.defaultProps = {
  id: 0,
  type: "text",
  title: "Title",
  defaultValue: "",
  placeholder: "placeholder",
  message: null,
  eye: false,
  error: false,
};

export default Input;

// --- // Documentation // --- //
/*
import Input from './../Nano/Input.js';

<Input
    id={0}
    title='Username'
    value={state or prop Value}
    placeholder='Username'
    error={false}
    onChangeAction={() => console.log('onChangeAction')}
/>

<Input
    id={0}
    type={this.state.showPassword ? 'text' : 'password'}
    title='Password'
    value={state or prop Value}
    placeholder=''
    eye={true}
    showPassword={this.state.showPassword}
    error={this.state.error !== null}
    onClickEyeToggle={() => this.setState({ showPassword: !this.state.showPassword })}
    onChangeAction={(currentValue, id, title, type) => this.passwordChange(currentValue)}
/>
*/
