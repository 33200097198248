import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Settings from "../../Global/Settings.json";
import moment from "moment";
import Type from "../../Global/Typography.js";

// SERVICES
import clientService from "../../../services/ClientService";

// COMPONENTS
import Input from "../../Elements/Forms/Input.js";
import CalendarRange from "../../Elements/Forms/CalendarRange.js";
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import ButtonIcon from "../../Elements/Buttons/ButtonIcon.js";
import SearchBar from "../../Elements/Forms/SearchBar.js";
import LogLine from "../Cards/LogLine.js";

import Loading from "../../Elements/Loadings/Loading.js";

// IMAGES
import DefaultClient from "../../Global/icons/defaultClient.svg";
import { ReactComponent as PhotoIcon } from "../../Global/icons/photo.svg";
import CopyIcon from "../../Global/icons/copy.svg";

const StyledClientProfile = styled(NanoFlex)`
  margin: 8px;
  .clientProfileWrapper {
    background-color: ${(props) => props.theme.color.greyPalette.white};
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    position: relative;

    .statusClientWrapper {
      display: ${(props) => (props.clientId > 0 ? "inherit" : "none")};
      width: auto;
      height: auto;
      position: absolute;
      top: 16px;
      right: 16px;
      .statusClient {
        width: 24px;
        height: 24px;
        border-radius: 50px;
        background-color: ${(props) => (props.inactiveStatus ? props.theme.color.main.red : props.theme.color.main.green)};
        border: 2px solid ${(props) => props.theme.color.greyPalette.white};
      }
    }
    .logoContainer {
      background-color: ${(props) => props.theme.color.greyPalette.white};
      width: 300px;
      padding: 10px;
      position: relative;

      img {
        max-width: 80%;
        max-height: 180px;
        width: 100%;
        height: auto;
      }
      .editPhotoBtn {
        width: auto;
        height: auto;
        cursor: pointer;
        position: absolute;
        bottom: 16px;
        right: 16px;
        svg {
          width: 24px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.greyPalette.lightGrey};
          }
        }
        &:hover {
          svg {
            path {
              fill: ${(props) => props.theme.color.greyPalette.grey};
            }
          }
        }
      }
    }

    .infoContainer {
      background-color: ${(props) => props.theme.color.greyPalette.secLighterGrey};
      padding: 24px;
      .mainInfo {
        background-color: ${(props) => props.theme.color.greyPalette.white};
        border-radius: 20px;
        overflow: auto;
        padding: 24px;
        .infoWrapper {
          height: auto;
          margin-bottom: 32px;
          .titleInfoWrapper {
            height: auto;
            margin-bottom: 8px;
            h4 {
              b {
                text-transform: uppercase;
              }
            }
            h6 {
              b {
                color: ${(props) => props.theme.color.main.blue};
                font-size: 12px;
              }
            }
          }
          .formWrapper {
            .inputWrapper {
              margin-bottom: 16px;
              .labelMultipleIpuntWrapper {
                margin-bottom: 4px;
              }
              .inputContainer {
                margin-right: 6px;
                &:last-child {
                  margin-right: 0;
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
              .inputWrapper-small {
                width: 200px;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .logsBlock {
        .topBar {
          height: auto;
          .asideBar {
            width: auto;
            .searchBarWrapper {
              input {
                padding: 4px 52px 4px 16px;
              }
              .searchIconWrapper {
                svg {
                  height: 16px;
                }
              }
            }
            .filterBtn {
              width: auto;
              margin-right: 8px;
              padding: 8px 12px;
              cursor: pointer;
              border-radius: 30px;
              background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
              user-select: none;
              &:hover {
                opacity: 0.7;
              }
              &:last-child {
                margin-right: 0;
              }
            }
            .selectedBtn {
              background-color: ${(props) => props.theme.color.main.green};
              h6 {
                color: white;
              }
            }
            .buttonIcon {
              margin-left: 8px;
              .iconWrapper {
                width: 31px;
                height: 31px;
                background-color: ${(props) => props.theme.color.greyPalette.lighterGrey};
                img {
                  width: 14px;
                }
              }
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
        .logListWrapper {
          margin-top: 16px;
          overflow: auto;
        }
      }
      .buttonsWrapper {
        margin-top: 24px;
        height: auto;
        .asideWrapper {
          height: auto;
          .backTolistBtn {
            cursor: pointer;
            svg {
              height: 16px;
              width: auto;
              margin-right: 6px;
              path {
                fill: ${(props) => props.theme.color.main.font};
              }
            }
            &:hover {
              opacity: 0.8;
            }
          }
          .simpleBtn {
            cursor: pointer;
            width: auto;
            padding: 8px 16px;
            border-radius: 60px;
            background-color: ${(props) => props.theme.color.greyPalette.white};
            user-select: none;
            h6 {
              text-transform: uppercase;
              transition: ${(props) => props.theme.transition};
            }
            &:hover {
              h6 {
                transition: ${(props) => props.theme.transition};
                font-weight: bold;
              }
            }
          }
          .cancelBtn {
            cursor: pointer;
            width: auto;
            margin-right: 32px;
            user-select: none;
            h4 {
              text-transform: uppercase;
            }
            &:hover {
              h4 {
                color: ${(props) => props.theme.color.main.red};
              }
            }
          }
          .submitBtn {
            width: auto;
          }
        }
      }
    }
  }
`;

class ClientProfile extends BaseComponent {
    constructor(props) {
        super(props);

        this.fileRef = React.createRef();

        this.state = {
            isLoading: true,
            versionId: this.props.versionId,
            clientId: this.props.clientId,
            lastClientId: this.props.lastSelectedClientId,
            client: {
                token: "",
                isActive: true,
                name: "",
                address: "",
                contactName: "",
                email: "",
                phoneNumber: "",
                licenseStartDate: null,
                licenseEndDate: null,
                fullLicensesAmount: 0,
                consultantLicensesAmount: 0,
                clientProfileImageFilePath: DefaultClient,
            },
            log: {
                clientToken: "",
                userID: null,
                dateTime: null,
                plantformID: "",
                plantformName: null,

            },
            logList: [],
            showLog: false,
            freeText: "",
            selectedPlatforms: [1, 2, 3],
        };
    }

    componentDidMount() {
        super.componentDidMount();

        this.getClientProfile();
        this.getClientLogs();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleFreeTextChange = (freeText) => {
        if ((this.state.freeText ?? "") !== (freeText ?? "")) {
            this.setState({
                ...this.state,
                freeText: freeText,
            });
        }
    };

    getClientProfile = () => {
        if (this.state.clientId > 0) {
            this.setState({
                ...this.state,
                isLoading: true,
            });

            clientService
                .getClient(this.state.clientId)
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                    }
                })
                .then((data) => {
                    if (data) {
                        this.setState({
                            ...this.state,
                            clientId: data.id,
                            lastClientId: this.state.clientId,
                            client: {
                                token: data.token ?? "",
                                isActive: data.isActive ?? true,
                                name: data.name ?? "",
                                address: data.address ?? "",
                                contactName: data.contactName ?? "",
                                email: data.email ?? "",
                                phoneNumber: data.phoneNumber ?? "",
                                licenseStartDate: data.licenseStartDate,
                                licenseEndDate: data.licenseEndDate,
                                fullLicensesAmount: data.fullLicensesAmount ?? 0,
                                consultantLicensesAmount: data.consultantLicensesAmount ?? 0,
                                clientProfileImageFilePath: data.clientProfileImageFilePath ?? DefaultClient,
                            },
                        });
                    }

                    this.setState({
                        ...this.state,
                        isLoading: false,
                    });
                });
        } else if (this.state.clientId === 0) {
            this.setState({
                ...this.state,
                isLoading: false,
                clientId: 0,
                client: {
                    token: "",
                    name: "",
                    address: "",
                    contactName: "",
                    email: "",
                    phoneNumber: "",
                    licenseStartDate: null,
                    licenseEndDate: null,
                    fullLicensesAmount: 0,
                    consultantLicensesAmount: 0,
                    clientProfileImageFilePath: DefaultClient,
                },
            });
        }
    };

    getClientLogs = () => {
        clientService
            .getClientsLogs(this.state.clientId)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                }
            })
            .then((data) => {
                this.setState({ logList: data });
            })
    };

    saveClientData = () => {
        this.setState({
            ...this.state,
            isLoading: true,
        });

        let client = {
            ...this.state.client,
            id: this.state.clientId,
            version: {
                id: this.props.versionId,
            },
        };

        clientService
            .saveClientData(client)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                }
            })
            .then((data) => {
                if (data) {
                    this.setState({
                        ...this.state,
                        clientId: data.id,
                        lastClientId: this.state.clientId,
                        client: {
                            token: data.token ?? "",
                            isActive: data.isActive ?? true,
                            name: data.name ?? "",
                            address: data.address ?? "",
                            contactName: data.contactName ?? "",
                            email: data.email ?? "",
                            phoneNumber: data.phoneNumber ?? "",
                            licenseStartDate: data.licenseStartDate,
                            licenseEndDate: data.licenseEndDate,
                            fullLicensesAmount: data.fullLicensesAmount ?? 0,
                            consultantLicensesAmount: data.consultantLicensesAmount ?? 0,
                            clientProfileImageFilePath: data.clientProfileImageFilePath ?? DefaultClient,
                        },
                    });
                }

                this.props.setSelectedClientId(this.state.clientId);
                this.props.getClients();

                this.setState({
                    ...this.state,
                    isLoading: false,
                });
            });
    };

    cancel = () => {
        if (this.state.clientId !== this.state.lastClientId) {
            this.props.setSelectedClientId(this.state.lastClientId);
        }
    };

    openImageFile = () => {
        this.fileRef.current.click();
    };

    saveClientImage = () => {
        if (this.fileRef && (this.fileRef.current.files.length || 0) > 0) {
            const formData = new FormData();

            formData.append("file", this.fileRef.current.files[0]);

            clientService
                .saveClientImg(this.state.clientId, formData)
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        if (response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
                    }
                })
                .then((data) => {
                    if (data) {
                        this.getClientProfile();
                        this.getClientLogs();
                        this.props.getClients();
                    }
                });
        }
    };

    toggleLog = () => {
        this.setState({ showLog: !this.state.showLog });
    };

    setSelectedPlatforms = (platform) => {
        if (this.state.selectedPlatforms.includes(platform)) {
            this.setState({ selectedPlatforms: this.state.selectedPlatforms.filter((p) => p !== platform) });
        }
        else {
            this.setState({ selectedPlatforms: [...this.state.selectedPlatforms, ...[platform]] });
        }
    };

    getFilteredLogList = () => {
        if (this.state.logList) {
            let options = JSON.parse(JSON.stringify(this.state.logList));

            if (this.state.freeText.trim() !== "") {
                let upperFreeText = this.state.freeText.trim().toUpperCase();
                options = options.filter((v) => {
                    let upperError = v.error.trim().toUpperCase();
                    let upperMethod = v.method.trim().toUpperCase();
                    let date = moment(v.date).format('L');
                    return upperError.includes(upperFreeText) ||
                        upperMethod.includes(upperFreeText) ||
                        date.includes(upperFreeText);
                });
            }

            if (this.state.selectedPlatforms?.length > 0) {
                options = options.filter((v) => this.state.selectedPlatforms.includes(v.platformID));
            }

            return options;
        }
        else
            return this.state.logList;
    };

    render() {
        return (
            <StyledClientProfile flexDirection="column" inactiveStatus={!this.state.client?.isActive}>
                {this.state.isLoading && (
                    <NanoFlex className="clientProfileWrapper">
                        <NanoFlex className="loadingContainer">
                            <Loading />
                        </NanoFlex>
                    </NanoFlex>
                )}

                {!this.state.isLoading && (
                    <NanoFlex className="clientProfileWrapper">
                        <NanoFlex className="statusClientWrapper">
                            <NanoFlex className="statusClient" title="Ativo"></NanoFlex>
                        </NanoFlex>

                        <NanoFlex className="logoContainer">
                            <img src={this.state.client.clientProfileImageFilePath} alt="Logo" />
                            <NanoFlex className="editPhotoBtn" onClick={this.openImageFile}>
                                <PhotoIcon />
                                <input ref={this.fileRef} type="file" onChange={this.saveClientImage} style={{ display: "none" }} accept=".png,.jpg,.jpeg" />
                            </NanoFlex>
                        </NanoFlex>

                        <NanoFlex className="infoContainer" flexDirection="column">
                            {/* FORM */}
                            {!this.state.showLog && (
                                <NanoFlex className="mainInfo formBlock" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                    <NanoFlex className="infoWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                        <NanoFlex className="titleInfoWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                            <Type.h4>
                                                <b>Licença</b>
                                            </Type.h4>
                                            {this.state.client.token.length > 0 && (
                                                <Type.h6>
                                                    <b>TOKEN</b> {this.state.client.token}
                                                </Type.h6>
                                            )}
                                        </NanoFlex>
                                        <NanoFlex className="formWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                            <NanoFlex className="inputWrapper">
                                                <Input
                                                    id={0}
                                                    title="Cliente"
                                                    placeholder="Inserir nome cliente..."
                                                    error={false}
                                                    forceValue={true}
                                                    defaultValue={this.state.client.name}
                                                    onChangeAction={(value) => {
                                                        this.setState((prevState) => ({
                                                            ...this.state,
                                                            client: {
                                                                ...prevState.client,
                                                                name: value,
                                                            },
                                                        }));
                                                    }}
                                                />

                                                {/* SE SÓ HOUVER ACESSOS COMPLETOS */}
                                                {this.props.versionId !== 1 && (
                                                    <NanoFlex className="inputWrapper-small">
                                                        <Input
                                                            alignRight
                                                            id={0}
                                                            title="Acessos"
                                                            placeholder="0"
                                                            error={false}
                                                            forceValue={true}
                                                            defaultValue={this.state.client.fullLicensesAmount}
                                                            onChangeAction={(value) => {
                                                                this.setState((prevState) => ({
                                                                    ...this.state,
                                                                    client: {
                                                                        ...prevState.client,
                                                                        fullLicensesAmount: parseInt(value),
                                                                    },
                                                                }));
                                                            }}
                                                        />
                                                    </NanoFlex>
                                                )}
                                            </NanoFlex>

                                            {/* SE SÓ HOUVER ACESSOS COMPLETOS E COLABORADORES*/}
                                            {this.props.versionId === 1 && (
                                                <NanoFlex className="inputWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                                    <NanoFlex className="labelMultipleIpuntWrapper" justifyContent="flex-start" alignItems="flex-start">
                                                        <Type.p>
                                                            <b>Acessos</b>
                                                        </Type.p>
                                                    </NanoFlex>
                                                    <NanoFlex className="inputWrapper">
                                                        <Input
                                                            alignRight
                                                            id={0}
                                                            title="Completo"
                                                            placeholder="0"
                                                            error={false}
                                                            forceValue={true}
                                                            defaultValue={this.state.client.fullLicensesAmount}
                                                            onChangeAction={(value) => {
                                                                this.setState((prevState) => ({
                                                                    ...this.state,
                                                                    client: {
                                                                        ...prevState.client,
                                                                        fullLicensesAmount: parseInt(value),
                                                                    },
                                                                }));
                                                            }}
                                                        />
                                                        <Input
                                                            alignRight
                                                            id={0}
                                                            title="Colaboradores"
                                                            placeholder="0"
                                                            error={false}
                                                            forceValue={true}
                                                            defaultValue={this.state.client.consultantLicensesAmount}
                                                            onChangeAction={(value) => {
                                                                this.setState((prevState) => ({
                                                                    ...this.state,
                                                                    client: {
                                                                        ...prevState.client,
                                                                        consultantLicensesAmount: parseInt(value),
                                                                    },
                                                                }));
                                                            }}
                                                        />
                                                    </NanoFlex>
                                                </NanoFlex>
                                            )}

                                            <NanoFlex className="inputWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                                <NanoFlex className="labelMultipleIpuntWrapper" justifyContent="flex-start" alignItems="flex-start">
                                                    <Type.p>
                                                        <b>Período</b>
                                                    </Type.p>
                                                </NanoFlex>
                                                <NanoFlex className="inputWrapper">
                                                    <CalendarRange
                                                        startDate={this.formatDate(this.state.client.licenseStartDate, "DD-MM-YYYY")}
                                                        endDate={this.formatDate(this.state.client.licenseEndDate, "DD-MM-YYYY")}
                                                        onChangeSelection={(obj) => {
                                                            this.setState({
                                                                ...this.state,
                                                                client: {
                                                                    ...this.state.client,
                                                                    licenseStartDate: this.getDate(obj.startDate ?? this.state.client.licenseStartDate),
                                                                    licenseEndDate: this.getDate(obj.endDate ?? this.state.client.licenseEndDate),
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </NanoFlex>
                                            </NanoFlex>
                                        </NanoFlex>
                                    </NanoFlex>

                                    <NanoFlex className="infoWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                        <NanoFlex className="titleInfoWrapper" justifyContent="flex-start" alignItems="flex-start">
                                            <Type.h4>
                                                <b>Informação cliente</b>
                                            </Type.h4>
                                        </NanoFlex>
                                        <NanoFlex className="formWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                            <NanoFlex className="inputWrapper">
                                                <Input
                                                    id={0}
                                                    title="Morada"
                                                    placeholder="Inserir morada..."
                                                    error={false}
                                                    forceValue={true}
                                                    defaultValue={this.state.client.address}
                                                    onChangeAction={(value) => {
                                                        this.setState((prevState) => ({
                                                            ...this.state,
                                                            client: {
                                                                ...prevState.client,
                                                                address: value,
                                                            },
                                                        }));
                                                    }}
                                                />
                                            </NanoFlex>

                                            <NanoFlex className="inputWrapper">
                                                <Input
                                                    id={0}
                                                    title="Nome de Contato"
                                                    placeholder="Inserir nome de contato..."
                                                    error={false}
                                                    forceValue={true}
                                                    defaultValue={this.state.client.contactName}
                                                    onChangeAction={(value) => {
                                                        this.setState((prevState) => ({
                                                            ...this.state,
                                                            client: {
                                                                ...prevState.client,
                                                                contactName: value,
                                                            },
                                                        }));
                                                    }}
                                                />
                                            </NanoFlex>

                                            <NanoFlex className="inputWrapper" justifyContent="flex-start" alignItems="flex-start">
                                                <Input
                                                    id={0}
                                                    title="Email"
                                                    placeholder="Inserir email..."
                                                    error={false}
                                                    forceValue={true}
                                                    defaultValue={this.state.client.email}
                                                    onChangeAction={(value) => {
                                                        this.setState((prevState) => ({
                                                            ...this.state,
                                                            client: {
                                                                ...prevState.client,
                                                                email: value,
                                                            },
                                                        }));
                                                    }}
                                                />
                                                <Input
                                                    id={0}
                                                    title="Telemóvel"
                                                    placeholder="9xxxxxxxx"
                                                    error={false}
                                                    forceValue={true}
                                                    defaultValue={this.state.client.phoneNumber}
                                                    onChangeAction={(value) => {
                                                        this.setState((prevState) => ({
                                                            ...this.state,
                                                            client: {
                                                                ...prevState.client,
                                                                phoneNumber: value,
                                                            },
                                                        }));
                                                    }}
                                                />
                                            </NanoFlex>
                                        </NanoFlex>
                                    </NanoFlex>
                                </NanoFlex>
                            )}
                            {/* LOGS */}
                            {this.state.showLog && (
                                <NanoFlex className="mainInfo logsBlock" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                    <NanoFlex className="topBar" justifyContent="space-between">
                                        <NanoFlex className="asideBar" justifyContent="flex-start">
                                            <NanoFlex className={`filterBtn ${this.state.selectedPlatforms.includes(1) ? "selectedBtn" : ""}`} onClick={() => this.setSelectedPlatforms(1)}>
                                                <Type.h6>Portal</Type.h6>
                                            </NanoFlex>
                                            <NanoFlex className={`filterBtn ${this.state.selectedPlatforms.includes(2) ? "selectedBtn" : ""}`} onClick={() => this.setSelectedPlatforms(2)}>
                                                <Type.h6>API</Type.h6>
                                            </NanoFlex>
                                            <NanoFlex className={`filterBtn ${this.state.selectedPlatforms.includes(3) ? "selectedBtn" : ""}`} onClick={() => this.setSelectedPlatforms(3)}>
                                                <Type.h6>Backoffice</Type.h6>
                                            </NanoFlex>
                                        </NanoFlex>
                                        <NanoFlex className="asideBar" justifyContent="flex-end">
                                            <SearchBar placeholder="Pesquisar..." handleFreeTextChange={this.handleFreeTextChange} freeText={this.state.freeText} />
                                            <ButtonIcon small icon={CopyIcon} alt="Copiar" />
                                        </NanoFlex>
                                    </NanoFlex>
                                    <NanoFlex className="logListWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                        {
                                            this.getFilteredLogList()?.map((item, i) => {

                                                return (
                                                    <LogLine key={item.id} date={`${moment(item.date).format('L')}`} hour={`${moment(item.date).format('LT')}`} clientID={`${item.clientID}`} userID={`${item.userID}`} platformID={`${item.platformID}`} method={`${item.method}`} error={`${item.error}`} />
                                                );
                                            })}
                                        {/* 
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="1-Portal" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-API" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-Backoffice" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="1-Portal" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-API" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-Backoffice" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="1-Portal" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-API" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-Backoffice" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="1-Portal" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-API" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-Backoffice" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="1-Portal" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-API" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        <LogLine date="25/01/2022" hour="09:48:35" clientID="1234" userID="adas86i69sad" platformID="2-Backoffice" method="/UserPrivileges/GetPrivilegesForConsultant" error="Input string was not in a correct format." />
                                        LOGS */}
                                    </NanoFlex>
                                </NanoFlex>
                            )}

                            <NanoFlex className="buttonsWrapper" justifyContent="space-between">
                                <NanoFlex className="asideWrapper" justifyContent="flex-start">
                                    <NanoFlex
                                        className="simpleBtn"
                                        onClick={() => {
                                            this.toggleLog();
                                        }}>
                                        {!this.state.showLog && <Type.h6>Ver Log</Type.h6>}
                                        {this.state.showLog && <Type.h6>Fechar Log</Type.h6>}
                                    </NanoFlex>
                                </NanoFlex>
                                <NanoFlex className="asideWrapper" justifyContent="flex-end">
                                    <NanoFlex
                                        className="cancelBtn"
                                        onClick={() => {
                                            this.cancel();
                                        }}>
                                        <Type.h4>Cancelar</Type.h4>
                                    </NanoFlex>
                                    <NanoFlex
                                        className="submitBtn"
                                        onClick={() => {
                                            this.saveClientData();
                                        }}>
                                        <ButtonCTA smallCta label="Guardar" />
                                    </NanoFlex>
                                </NanoFlex>
                            </NanoFlex>
                        </NanoFlex>
                    </NanoFlex>
                )}
            </StyledClientProfile>
        );
    }
}

export default ClientProfile;

// --- // Documentation // --- //
/*
    import ClientCard from './Cards/ClientCard.js';

    <ClientCard />
    */
